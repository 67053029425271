
import Swiper from "swiper";
export default {
    name: 'partners',
    components: {
    },
    data() {
        return {
            swiperPatnersListOne: [
                require('./img/服务伙伴01.png'),
                require('./img/服务伙伴02.png'),
                require('./img/服务伙伴03.png'),
                require('./img/服务伙伴04.png'),
            ],// 服务伙伴轮播
            swiperPatnersList: [
                require('./img/营销伙伴01.png'),
                require('./img/营销伙伴02.png'),
                require('./img/营销伙伴03.png'),
                require('./img/营销伙伴04.png'),
                require('./img/营销伙伴05.png'),
                require('./img/营销伙伴06.png'),
                require('./img/营销伙伴07.png'),
                require('./img/营销伙伴08.png'),
                require('./img/营销伙伴09.png'),
            ],// 合作伙伴轮播
            marketingList: [],
            partnersSwiperOne: null,
            partnersSwiperCustom: null,
            // elmentList:
            //     [
            //         {
            //             pcElementId: 404,
            //             phoneElementId: 401,
            //             descList: [
            //                 {
            //                     descName: '合作伙伴',
            //                     sort: 1
            //                 }, {
            //                     descName: '七腾机器人立足石油化工行业企业需求和智能机器人行业发展，',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: '与服务伙伴精诚协作，与营销伙伴互利共赢，与企业客户共同发展，致力发展成为全球机器人领先企业。',
            //                     sort: 3
            //                 }],
            //             type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 405,
            //             phoneElementId: 402,
            //             descList: [
            //                 {
            //                     descName: '服务伙伴',
            //                     sort: 1
            //                 }, {
            //                     descName: '七腾机器人与众多供应商、服务商达成合作伙伴关系，通过与这些优秀的合作伙伴精诚协作、携手共点，进而实现资源共亭、优势互补，从而提高产品质量、降低成本、提升市场竞争力。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 406,
            //             phoneElementId: 403,
            //             descList: [
            //                 {
            //                     descName: '营销伙伴',
            //                     sort: 1
            //                 }, {
            //                     descName: '七腾机器人秉承“合作共赢、耕耘收获”的合作理念，通过与代理商、经销商伙伴建立紧密的合作关系，共同进行产品和解决方案的推广落地，依托营销伙伴丰富的专业知识和资源渠道，为七腾机器人开拓新市场、拓宽销售渠道提供强有力的加持。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 407,
            //             phoneElementId:'',
            //             descList: [
            //                 {
            //                     descName: '企业客户',
            //                     sort: 1
            //                 }, {
            //                     descName: '七腾机器人已为全球40多个国家及地区的上千家企业提供产品服务和标准化解决方案，通过与这些企业客户建立紧密的合作关系，可以更好地了解市场需求，优化产品和服务，提高客户满意度，从而实现双方的共同发展。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 4
            //         }
            //     ]
            elmentList: []
        }
    },
    mounted() {
        //    this.$store.dispatch("pageset/getSourceData",
        //             {
        //                 createBy: '',
        //                 updateBy: '',
        //                 elmentList: this.elmentList,
        //                 pageId: this.$route.query.pageId,//页面id
        //                 pageName: this.$route.path,
        //                 path: this.$route.path,
        //                 remark: '',
        //             }
        //         );

        // // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            this.$nextTick(() => {
                if (this.$store.state.pageset.pcshow) {
                    this.initPartnersSwiper();
                    this.initPartnersSwiperOne();
                    this.initPartnersSwiperCustom();
                }
            });
        });
        let arr = [];
        for (let i = 0; i < 56; i++) {
            arr.push(i);
        }
        for (let i = 0; i < arr.length; i += 8) {
            this.marketingList.push(arr.slice(i, i + 8))
        }
    },
    // 销毁滚轮事件
    destroyed() {
        // 销毁swiper
        this.partnersSwiper.destroy();
        this.partnersSwiperOne.destroy();
        this.partnersSwiperCustom.destroy();
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        // 初始化banner轮播图
        initPartnersSwiper() {
            let _that = this;
            this.partnersSwiper = new Swiper('.swiper-patner', {
                speed: 700,
                allowTouchMove: false,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                },
                centeredSlides: true,
                spaceBetween: 50,
                // slidesOffsetBefore: 40,
                loop: true,
                slidesPerView: 9,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
            window.onresize = function () {
                _that.initPartnersSwiper.update();
            }
        },

        initPartnersSwiperOne() {
            let _that = this;
            this.partnersSwiperOne = new Swiper('.swiper-patner-one', {
                // speed: 700,
                allowTouchMove: false,
                freeMode: true,
                lazy: {

                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                },
                centeredSlides: true,
                spaceBetween: -30,
                slidesOffsetBefore: 0,
                loop: true,
                slidesPerView: 4,
                navigation: {
                    nextEl: '.swiper-button-next-one',
                    prevEl: '.swiper-button-prev-one',
                },
                on: {},

            });
            window.onresize = function () {
                _that.initPartnersSwiperOne.update();
            }
        },
        initPartnersSwiperCustom() {
            let _that = this;
            this.partnersSwiperCustom = new Swiper('.swiper-patner-custom', {
                // speed: 700,
                allowTouchMove: false,
                freeMode: true,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                },
                centeredSlides: true,
                slidesOffsetBefore: 0,
                slidesPerView: 1,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next-one',
                    prevEl: '.swiper-button-prev-one',
                },
                on: {},

            });
            window.onresize = function () {
                _that.partnersSwiperCustom.update();
            }
        },
        openPdf() {
             this.$router.push('/pdfView')
        },
    }
}