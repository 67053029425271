<template>
  <!-- pc -->
  <div class="patners">
    <div class="banner_video" :id="'elm' + elmentList[0].id">
      <img :src="elmentList[0].pcImg"  v-if="$store.state.pageset.pcshow"/>
      <img :src="elmentList[0].phoneImg"  v-else/>
      <div class="img-con">
        <div class="title" v-if="$store.state.pageset.pcshow">
          <p class="p1">{{ elmentList[0].descList[0].descName }}</p>
          <p class="p2">{{ elmentList[0].descList[1].descName }}</p>
          <p class="p2">{{ elmentList[0].descList[2].descName }}</p>
        </div>
        <div class="title" v-else>
          <p class="p1">{{ elmentList[0].descList[0].descName }}</p>
          <p class="p2">{{ elmentList[0].descList[1].descName }}{{ elmentList[0].descList[2].descName }}</p>
        </div>
      </div>
    </div>
    <div v-if="$store.state.pageset.pcshow" class="patner-div">
      <img class="bc-img" :src="elmentList[1].pcImg" />
      <div class="patner-content patner-content-one">
        <div class="swiper-patner-one">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(hnItem, index) in swiperPatnersListOne"
              :key="index"
            >
              <img
                :src="hnItem"
                width="100%"
                height="100%"
                class="swiper-lazy"
              />
            </div>
          </div>
        </div>
        <div class="swiper-button-prev-one"></div>
        <div class="swiper-button-next-one"></div>
      </div>
    </div>
    <div v-else class="patner-div">
      <img class="bc-img" :src="elmentList[1].phoneImg" />
      <div class="patner-content patner-content-one" >
      <div class="swiper-patner">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div
              class="swiper-slide-div"
              v-for="(hnItem, index) in swiperPatnersListOne"
              :key="index"
            >
              <img
                :src="hnItem"
                width="100%"
                height="100%"
                class="swiper-lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>
    </div>
    <div class="content-dec">
      <div class="content-dec-left">
        <P>{{ elmentList[1].descList[0].descName }}</P>
      </div>
      <div class="content-dec-right">
        <P>{{ elmentList[1].descList[1].descName }}</P>
      </div>
    </div>
    <div v-if="this.$store.state.pageset.pcshow" class="patner-div">
      <img class="bc-img" :src="elmentList[2].pcImg" />
      <div class="patner-content patner-content-one">
        <div class="swiper-patner-one">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(hnItem, index) in swiperPatnersList"
              :key="index"
            >
              <img
                :src="hnItem"
                width="100%"
                height="100%"
                class="swiper-lazy"
              />
            </div>
          </div>
        </div>
        <div class="swiper-button-prev-one"></div>
        <div class="swiper-button-next-one"></div>
      </div>
    </div>
    <div v-else class="patner-div">
      <img class="bc-img" :src="elmentList[2].phoneImg" />
      <div class="patner-content patner-content-one" >
      <div class="swiper-patner">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div
              class="swiper-slide-two "
              v-for="(hnItem, index) in swiperPatnersList"
              :key="index"
            >
              <img
                :src="hnItem"
                width="100%"
                height="100%"
                class="swiper-lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>
    </div>
    <div class="content-dec">
      <div class="content-dec-left">
        <P>{{ elmentList[2].descList[0].descName }}</P>
      </div>
      <div class="content-dec-right">
        <P>{{ elmentList[2].descList[1].descName }}</P>
      </div>
    </div>
    <div v-if="this.$store.state.pageset.pcshow" class="patner-div">
      <img class="bc-img" :src="elmentList[3].pcImg" />
      <div class="patner-content patner-content-one">
        <div class="swiper-patner-one swiper-patner-custom">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="index in 5" :key="index">
              <div class="slide-item" v-for="indexT in 12" :key="indexT">
                <div v-if="index<5">
                  <img
                    :src="require('./img/' +((index-1)*12 +indexT) + '.png')"
                    class="swiper-lazy"
                  />
                </div>
                <div v-else-if="((index-1)*12 +indexT)<57">
                  <img
                    :src="require('./img/' +((index-1)*12 +indexT)+ '.png')"
                    class="swiper-lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev-one"></div>
        <div class="swiper-button-next-one"></div>
      </div>
    </div>
    <div class="marketing-partners" v-else>
      <div
        v-for="(item, index) in 56"
        :key="index"
        class="marketing-partners-item"
      >
        <img  :src="require('./img/' +(item) + '.png')" />
      </div>
    </div>
    <div class="content-dec content-three">
      <div class="content-dec-left">
        <P>{{ elmentList[3].descList[0].descName }}</P>
      </div>
      <div class="content-dec-right">
        <P>{{ elmentList[3].descList[1].descName }}</P>
      </div>
    </div>
  </div>
</template>
<script>
import partners from "./partners.js";

export default partners;
</script>
<style scoped rel="stylesheet/stylus" lang=scss>
@import "~@/assets/style/common.scss";
@import "partners.scss";

/deep/.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  background: #5c5e62 !important;
  opacity: 1 !important;
  margin-right: 5px !important;
}

/deep/.swiper-pagination-bullet-active {
  background: #fff !important;
}
</style>